import type { ComponentPropsWithoutRef } from "react";

import { OngoingSessionBanner } from "@/domains/coaching/components";
import { useSessions } from "@/domains/coaching/hooks";
import { tw } from "@/utils";

type OngoingSessionsProps = ComponentPropsWithoutRef<"div">;

export const OngoingSessions = ({
  className,
  ...props
}: OngoingSessionsProps) => {
  const { data } = useSessions({
    filter: { status: "ongoing" },
  });
  const sessions = data?.data ?? [];

  return (
    <div className={tw("flex w-full flex-col gap-4", className)} {...props}>
      {sessions.map((session) => (
        <OngoingSessionBanner key={session.id} session={session} />
      ))}
    </div>
  );
};
