import { OngoingSessions } from "@/domains/coaching/sections";
import { ScreenLayout } from "@/layouts";
import { SetUpFridaysPasswordModal, SetUpPaymentMethodModal } from "@/modals";
import { HomeHeader } from "./sections";
import { HomeContent } from "./sections/Content";

export const Home = () => {
  return (
    <>
      <ScreenLayout variant="secondary" className="gap-2">
        <HomeHeader />

        <OngoingSessions className="pb-4" />

        <HomeContent />
      </ScreenLayout>

      <SetUpPaymentMethodModal />

      <SetUpFridaysPasswordModal />
    </>
  );
};
