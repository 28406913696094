import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { OnboardingActionButtons } from "@/domains/onboarding/components";
import { useNavigateWithCurrentQueryParams } from "@/domains/onboarding/hooks";
import { ROUTES } from "@/router";
import { useOnboardingStore } from "@/stores";

interface SubmitProps {
  onCancel: () => void;
}

export const Submit = ({ onCancel }: SubmitProps) => {
  const setAppointment = useOnboardingStore((state) => state.setAppointment);
  const navigateWithQuery = useNavigateWithCurrentQueryParams();

  const {
    language,
    scheduleDate: { timeSlot },
  } = useScheduleConsultationContext();

  const handleCancel = () => {
    setAppointment(null);
    onCancel();
  };

  const handleContinue = () => {
    if (timeSlot) {
      setAppointment({ ...timeSlot, language });
    }
    navigateWithQuery(ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT);
  };

  return (
    <OnboardingActionButtons
      onCancel={handleCancel}
      continueDisabled={!timeSlot}
      continueLabel={t`Confirm Consultation`}
      onContinue={handleContinue}
    />
  );
};
