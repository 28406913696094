export const EVERFLOW_EVENT_TYPES = {
  CONVERT: "convert",
} as const;

interface ConvertEvent {
  items: {
    price: number;
    name: string;
    sku: string;
  }[];
  orderId: string;
}

interface TrackPayload {
  action: string;
  order_id: string;
  sale_value: number;
  [key: string]: string | number;
}

export const handleKatalysConvertEvent = (payload: ConvertEvent): void => {
  _revoffers_track = window?._revoffers_track || [];
  const trackPayload: Partial<TrackPayload> = {
    action: EVERFLOW_EVENT_TYPES.CONVERT,
    order_id: payload.orderId,
  };
  let saleValue = 0;

  payload.items.map(({ price, name, sku }, i: number) => {
    const key = `line_item_${i + 1}`;
    const titleKey = `${key}_title`;
    const skuKey = `${key}_sku`;
    const priceKey = `${key}_price`;

    trackPayload[titleKey] = name;
    trackPayload[skuKey] = sku;
    trackPayload[priceKey] = price;
    saleValue += price;
  });

  trackPayload.sale_value = saleValue;

  _revoffers_track.push(trackPayload);
};
