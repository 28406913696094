import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import { useSearchParams } from "react-router-dom";

import { useOnboardingStore } from "@/stores";
import { onboardingQueriesStore } from "../api";

export const useUserFromEmbeddables = () => {
  const setUser = useOnboardingStore((state) => state.setUser);
  const user = useOnboardingStore((state) => state.user);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const cleanedCode = code && DOMPurify.sanitize(code);

  const query = useQuery(
    onboardingQueriesStore.onboarding.embeddables._ctx.user(cleanedCode),
  );

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  return { ...query, isError: query.isError || (!user && !code) };
};
