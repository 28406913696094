import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endOfMonth, subYears } from "date-fns";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getStatesQuery, updatePatient } from "@/api";
import { Loader } from "@/components";
import { usePatientQuery } from "@/hooks";
import { ROUTES } from "@/router";
import { NOW, VARIANT } from "@/shared.constants";
import { getGenderOptions, getTimezoneOptions } from "@/shared.options";
import { getPatientSchema } from "@/shared.schemas";
import type { Patient, PatientFormValues } from "@/shared.types";
import {
  Avatar,
  Button,
  Card,
  errorToast,
  HookedDatePicker,
  HookedSelect,
  icons,
  Input,
  ScreenHeader,
  useToastStore,
} from "@/ui";
import { FileDropzone } from "@/ui/form/FileDropzone";
import { handleAxiosFieldErrors } from "@/utils";

const getFormPatientValues = (data?: Patient) => {
  if (!data) {
    return {};
  }

  const { location, policy, gender, dateOfBirth, phoneNumber, ...rest } = data;
  return {
    values: {
      ...rest,
      ...policy,
      state: location?.state ?? "",
      line1: location?.line1 ?? "",
      line2: location?.line2 ?? "",
      zip: location?.zip ?? "",
      city: location?.city ?? "",
      country: location?.country ?? "US",
      gender: gender ?? "",
      dateOfBirth: dateOfBirth ?? "",
      phoneNumber: phoneNumber ?? "",
    },
  };
};

export const EditProfile = () => {
  const { pushToast } = useToastStore();
  const navigate = useNavigate();

  const { data: patient, isRefetching: isLoadingPatient } = usePatientQuery();

  const { data: stateOptions, isLoading: isLoadingStates } =
    useQuery(getStatesQuery());

  const {
    formState: { errors, dirtyFields },
    control,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    setValue,
  } = useForm<PatientFormValues>({
    resolver: zodResolver(getPatientSchema()),
    ...getFormPatientValues(patient),
  });
  const areValuesChanged = Object.keys(dirtyFields).length;

  const handleFileUpload = (
    file: File,
    field: "cardFrontImage" | "cardBackImage",
    idField: "cardFrontId" | "cardBackId",
  ) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      if (typeof base64String === "string") {
        setValue(field, base64String, { shouldDirty: true });
        setValue(idField, null, { shouldDirty: true });
      }
    };
    reader.readAsDataURL(file);
  };

  const queryClient = useQueryClient();
  const {
    mutate: updatePatientMutation,
    isPending: isPendingUpdatePatientMutation,
  } = useMutation({
    mutationFn: updatePatient.mutation,
    onSuccess: () => {
      updatePatient.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Profile successfully updated!`,
      });

      navigate(ROUTES.PROFILE.OVERVIEW);
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  const isLoading = isLoadingPatient || isLoadingStates;
  const isSuccess = patient && !isLoading;

  return (
    <div className="relative flex grow flex-col gap-5 px-4 pt-12 md:px-8 lg:px-16">
      <ScreenHeader
        variant="secondary"
        title={t`Edit profile`}
        description="Required fields *"
        paths={[
          {
            href: ROUTES.PROFILE.OVERVIEW,
            icon: icons.User,
            label: t`My profile`,
          },
          {
            href: ROUTES.PROFILE.EDIT,
            icon: icons.Pencil,
            label: t`Edit Profile`,
          },
        ]}
        actions={
          <div className="flex items-center gap-2">
            <Button
              variant={VARIANT.SECONDARY}
              onClick={() => {
                reset();
                navigate(ROUTES.PROFILE.OVERVIEW);
              }}
              className="hidden md:block"
            >{t`Cancel`}</Button>
            <Button
              type="submit"
              form="edit-patient-form"
              disabled={!areValuesChanged || isPendingUpdatePatientMutation}
            >{t`Save changes`}</Button>
          </div>
        }
      />

      {isLoading && <Loader />}
      {isSuccess && (
        <form
          id="edit-patient-form"
          onSubmit={handleSubmit((values) => updatePatientMutation(values))}
          className="flex grow flex-col gap-4 pb-10 text-brown-09"
        >
          <Card colorVariant="secondary" title={t`General information`}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-4.5">
              <div className="col-span-2 flex gap-2 sm:col-span-1">
                <Avatar
                  size="lg"
                  name={watch("firstName")}
                  className="translate-y-6"
                />
                <Input
                  label={t`First name *`}
                  placeholder={t`E.g. John`}
                  left={<icons.User />}
                  id="edit-profile-first-name"
                  {...register("firstName")}
                  error={errors.firstName?.message}
                />
              </div>
              <Input
                label={t`Second name *`}
                placeholder={t`E.g. Doe`}
                left={<icons.User />}
                id="edit-profile-last-name"
                {...register("lastName")}
                error={errors.lastName?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <HookedSelect
                label={t`Sex assigned at birth *`}
                placeholder={t`Select a gender`}
                id="edit-profile-gender"
                name="gender"
                left={<icons.Gender />}
                control={control}
                options={getGenderOptions()}
                error={errors.gender?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <HookedDatePicker
                label={t`Date of birth *`}
                id="dateOfBirth"
                name="dateOfBirth"
                left={<icons.Calendar />}
                control={control}
                error={errors.dateOfBirth?.message}
                config={{
                  years: {
                    mode: "exact",
                    numberOfYears: 100,
                    step: 0,
                  },
                  dates: {
                    maxDate: NOW,
                    minDate: endOfMonth(subYears(NOW, 99)),
                  },
                }}
                containerClassName="col-span-2 sm:col-span-1"
              />
            </div>
          </Card>

          <Card title={t`Contact`}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-4.5">
              <Input
                label={t`Email address *`}
                left={<icons.Envelope />}
                id="edit-profile-email"
                {...register("email")}
                error={errors.email?.message}
                readOnly
              />
              <Input
                label={t`House and street number *`}
                placeholder={t`E.g. 123, Main Street`}
                left={<icons.Home />}
                id="edit-profile-street"
                {...register("line1")}
                error={errors.line1?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                label={t`Apartment number`}
                placeholder={t`E.g. 1A`}
                left={<icons.Home />}
                id="edit-profile-street-2"
                {...register("line2")}
                error={errors.line2?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                label={t`Zip code *`}
                placeholder={t`E.g. 12345`}
                left={<icons.Home />}
                id="edit-profile-zip"
                {...register("zip")}
                error={errors.zip?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <HookedSelect
                disabled={isLoadingStates}
                autocomplete
                label={t`State *`}
                placeholder={t`E.g. New York`}
                id="state"
                name="state"
                left={<icons.Home />}
                control={control}
                options={stateOptions ?? []}
                error={errors.state?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                label={t`City *`}
                placeholder={t`E.g. Phoenix`}
                left={<icons.Home />}
                id="edit-profile-city"
                {...register("city")}
                error={errors.city?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                label={t`Phone number *`}
                placeholder={t`E.g. (555) 555-5555`}
                left={<icons.DevicePhone />}
                id="edit-profile-phone"
                {...register("phoneNumber")}
                error={errors.phoneNumber?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <HookedSelect
                label={t`Time zone *`}
                placeholder={t`E.g. PST`}
                id="edit-profile-timezone"
                name="timezone"
                left={<icons.Clock />}
                control={control}
                options={getTimezoneOptions()}
                error={errors.timezone?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
            </div>
          </Card>
          <Card title={t`Insurance`}>
            <div className="text-sm font-medium text-salmon-10">{t`Insurance card`}</div>
            <div className="grid gap-x-4 gap-y-4.5 sm:grid-cols-2">
              <FileDropzone
                fileExists={Boolean(watch("cardFrontId"))}
                id="cardFrontImage"
                onDrop={(acceptedFiles) => {
                  const firstFile = acceptedFiles[0];
                  if (firstFile) {
                    handleFileUpload(
                      firstFile,
                      "cardFrontImage",
                      "cardFrontId",
                    );
                  }
                }}
                label={t`Drop your front card image here`}
                uploadedLabel={t`Front card uploaded`}
                value={watch("cardFrontImage")}
              />
              <FileDropzone
                fileExists={Boolean(watch("cardBackId"))}
                id="cardBackImage"
                onDrop={(acceptedFiles) => {
                  const firstFile = acceptedFiles[0];
                  if (firstFile) {
                    handleFileUpload(firstFile, "cardBackImage", "cardBackId");
                  }
                }}
                label={t`Drop your back card image here`}
                uploadedLabel={t`Back card uploaded`}
                value={watch("cardBackImage")}
              />
            </div>
          </Card>
        </form>
      )}
    </div>
  );
};
