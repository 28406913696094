import { useState } from "react";
import { t } from "ttag";

import {
  FullScreenAlert,
  MedicationRequestFilter,
  OnboardingHeader,
} from "@/components";
import {
  DisqualifiedActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { useNavigateWithCurrentQueryParams } from "@/domains/onboarding/hooks";
import { ROUTES } from "@/router";
import { VARIANT } from "@/shared.constants";
import { illustratedIcons } from "@/ui";

interface DeliveryProps {
  onGoBack: () => void;
  onDisqualified: () => void;
}

export const Delivery = ({ onGoBack, onDisqualified }: DeliveryProps) => {
  const [showDisqualifiedScreen, setShowDisqualifiedScreen] = useState(false);
  const navigateWithQuery = useNavigateWithCurrentQueryParams();

  return (
    <OnboardingLayout className="bg-brown-02">
      <div className="flex flex-col gap-6">
        <OnboardingHeader title={t`Begin filling out your request form`} />
        <MedicationRequestFilter
          onBack={onGoBack}
          onNext={(isDisqualified) =>
            isDisqualified
              ? setShowDisqualifiedScreen(true)
              : navigateWithQuery(ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT)
          }
        />
      </div>

      {showDisqualifiedScreen && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Schedule variant={VARIANT.SECONDARY} />}
          title={t`Prior Consultation with Provider Required`}
          description={t`Due to your current medical situation, at this time we cannot make a refill request, you must see a provider first.`}
          customAction={
            <DisqualifiedActionButtons
              onGoBack={() => setShowDisqualifiedScreen(false)}
              onContinue={onDisqualified}
            />
          }
        />
      )}
    </OnboardingLayout>
  );
};
