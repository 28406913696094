import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { createContext, forwardRef, useContext } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import {
  Badge as BadgePrimitive,
  badgeVariants,
  Button as ButtonPrimitive,
  Label as LabelPrimitive,
} from "@/ui";

const detailCardVariants = tv({
  slots: {
    root: "flex flex-col gap-3 rounded-lg border border-l-8 bg-salmon-01 p-3",
    header: "flex flex-row gap-1 pr-4",
    title: "font-bold leading-5",
    content: "flex grow flex-col gap-3 px-4",
    badge: "w-full",
    label: "text-xs text-brown-06",
    description: "text-xs text-brown-08",
    separator: "-mx-3 h-[1px] bg-current",
    footer: "flex justify-between gap-4 px-4",
    button: "w-fit bg-brown-02 p-1.5",
  },
  variants: {
    color: {
      nature: {
        root: "border-nature-03",
        header: "text-nature-10",
        badge: "text-nature-10",
        separator: "bg-nature-03",
      },
      salmon: {
        root: "border-salmon-03",
        header: "text-salmon-10",
        badge: "text-salmon-10",
        separator: "bg-salmon-03",
      },
      brown: {
        root: "border-brown-03",
        header: "text-brown-10",
        badge: "text-brown-10",
        separator: "bg-brown-03",
      },
      "nature-dark": {
        root: "border-nature-04",
        header: "text-nature-10",
        badge: "text-nature-10",
        separator: "bg-nature-04",
      },
    },
    variant: {
      banner: {
        root: "grow border-none p-4",
        badge: "gap-2 px-4 py-1 text-base font-bold leading-5 sm:w-fit",
        header: "items-center",
        title: "text-xl font-bold leading-7",
        label: "text-sm",
        description: "text-sm",
      },
    },
    badgeVariant: badgeVariants.variants.variant,
  },

  compoundVariants: [
    {
      color: "nature",
      badgeVariant: "primary",
      className: {
        badge: "border-nature-03 bg-nature-03 text-nature-10",
      },
    },
    {
      color: "nature",
      badgeVariant: "outline",
      className: {
        badge: "border-nature-03 text-nature-10",
      },
    },
    {
      color: "salmon",
      badgeVariant: "primary",
      className: {
        badge: "border-salmon-03 bg-salmon-03 text-salmon-10",
      },
    },
    {
      color: "salmon",
      badgeVariant: "outline",
      className: {
        badge: "border-salmon-03 text-salmon-10",
      },
    },
    {
      color: "brown",
      badgeVariant: "primary",
      className: {
        badge: "border-brown-03 bg-brown-03 text-brown-10",
      },
    },
    {
      color: "brown",
      badgeVariant: "outline",
      className: {
        badge: "border-brown-03 text-brown-10",
      },
    },
    {
      color: "nature-dark",
      badgeVariant: "primary",
      className: {
        badge: "border-nature-04 bg-nature-04 text-nature-10",
      },
    },
    {
      color: "nature-dark",
      badgeVariant: "outline",
      className: {
        badge: "border-nature-04 text-nature-10",
      },
    },
    {
      color: "nature",
      variant: "banner",
      className: {
        root: "bg-nature-02",
        badge: "border-nature-03 bg-nature-03 text-nature-09",
        title: "text-nature-10",
      },
    },
    {
      color: "salmon",
      variant: "banner",
      className: {
        root: "bg-salmon-02",
        badge: "border-salmon-03 bg-salmon-03 text-salmon-09",
        title: "text-salmon-10",
      },
    },
    {
      color: "brown",
      variant: "banner",
      className: {
        root: "bg-brown-02",
        badge: "border-brown-03 bg-brown-03 text-brown-09",
        title: "text-brown-10",
      },
    },
    {
      color: "nature-dark",
      variant: "banner",
      className: {
        root: "bg-nature-dark-02",
        badge: "border-nature-dark-03 bg-nature-dark-03 text-nature-dark-09",
        title: "text-nature-dark-10",
      },
    },
  ],
});

const {
  root,
  header,
  title,
  content,
  badge,
  button,
  label,
  description,
  separator,
  footer,
} = detailCardVariants();

type DetailCardContextValues = Pick<
  VariantProps<typeof detailCardVariants>,
  "color" | "variant"
>;

const DetailCardContext = createContext<DetailCardContextValues>({
  color: "nature",
});

const useDetailCardContext = () => useContext(DetailCardContext);

const Root = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & DetailCardContextValues
>(({ className, color, variant, ...props }, ref) => (
  <DetailCardContext.Provider value={{ color, variant }}>
    <div ref={ref} className={root({ className, color, variant })} {...props} />
  </DetailCardContext.Provider>
));
Root.displayName = "DetailCard.Root";

const Header = forwardRef<
  ElementRef<"header">,
  ComponentPropsWithoutRef<"header">
>(({ className, ...props }, ref) => {
  const { color, variant } = useDetailCardContext();
  return (
    <header
      ref={ref}
      className={header({ className, color, variant })}
      {...props}
    />
  );
});
Header.displayName = "DetailCard.Header";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={content({ className })} {...props} />;
  },
);
Content.displayName = "DetailCard.Content";

const Footer = forwardRef<
  ElementRef<"footer">,
  ComponentPropsWithoutRef<"footer">
>(({ className, ...props }, ref) => {
  return <footer ref={ref} className={footer({ className })} {...props} />;
});
Footer.displayName = "DetailCard.Footer";

const Title = forwardRef<ElementRef<"h4">, ComponentPropsWithoutRef<"h4">>(
  ({ className, children, ...props }, ref) => {
    const { color, variant } = useDetailCardContext();

    return (
      <h4 ref={ref} className={title({ className, color, variant })} {...props}>
        {children}
      </h4>
    );
  },
);
Title.displayName = "DetailCard.Title";

const Badge = forwardRef<
  ElementRef<typeof BadgePrimitive>,
  ComponentPropsWithoutRef<typeof BadgePrimitive>
>(({ className, variant = "primary", ...props }, ref) => {
  const { color, variant: detailCardVariant } = useDetailCardContext();

  return (
    <BadgePrimitive
      ref={ref}
      rounded="3xl"
      variant={variant}
      className={badge({
        className,
        color,
        badgeVariant: variant,
        variant: detailCardVariant,
      })}
      {...props}
    />
  );
});
Badge.displayName = "DetailCard.Badge";

const Button = forwardRef<
  ElementRef<typeof ButtonPrimitive>,
  ComponentPropsWithoutRef<typeof ButtonPrimitive>
>(({ className, ...props }, ref) => {
  return (
    <ButtonPrimitive
      ref={ref}
      variant="cover-primary"
      className={button({ className })}
      {...props}
    />
  );
});
Button.displayName = "DetailCard.Button";

const Separator = forwardRef<
  ElementRef<typeof SeparatorPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref,
  ) => {
    const { color, variant } = useDetailCardContext();
    return (
      <SeparatorPrimitive.Root
        asChild
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={separator({ className, color, variant })}
        {...props}
      >
        <span />
      </SeparatorPrimitive.Root>
    );
  },
);
Separator.displayName = "DetailCard.Separator";

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => {
  const { variant } = useDetailCardContext();

  return (
    <LabelPrimitive
      ref={ref}
      className={label({ className, variant })}
      {...props}
    />
  );
});
Label.displayName = "DetailCard.Label";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    const { variant } = useDetailCardContext();

    return (
      <p ref={ref} className={description({ className, variant })} {...props} />
    );
  },
);
Description.displayName = "DetailCard.Description";

export {
  Root,
  Header,
  Title,
  Footer,
  Content,
  Badge,
  Button,
  Description,
  Label,
  Separator,
};
