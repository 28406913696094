import { Link } from "react-router-dom";
import { t } from "ttag";

import { SESSION_ICON_MAP } from "@/domains/coaching/models";
import {
  Banner,
  ClockIcon,
  DetailCard,
  IconWrapper,
  Shape7,
  UserCircleIcon,
  VideoCameraIcon,
} from "@/ui";
import type { Session } from "../models";

interface OngoingSessionBannerProps {
  session: Session;
}

export const OngoingSessionBanner = ({
  session,
}: OngoingSessionBannerProps) => {
  const {
    title,
    category,
    coach: { name: coachName },
    duration,
    joinUrl,
  } = session;

  return (
    <Banner.Root>
      <Banner.Background>
        <Shape7 className="size-64 translate-x-6 translate-y-12 -scale-x-100 self-start text-nature-04 opacity-40 lg:-translate-y-6" />
      </Banner.Background>

      <DetailCard.Root
        color="nature"
        variant="banner"
        className="xl:flex-row-reverse"
      >
        <DetailCard.Badge>
          <IconWrapper size="sm">
            <VideoCameraIcon />
          </IconWrapper>
          {t`Live!`}
        </DetailCard.Badge>

        <div className="flex grow flex-col justify-between gap-3 sm:flex-row lg:flex-col xl:flex-row xl:justify-start xl:gap-0">
          <DetailCard.Header>
            <IconWrapper size="sm">{SESSION_ICON_MAP[category]}</IconWrapper>
            <DetailCard.Title>{title}</DetailCard.Title>
          </DetailCard.Header>

          <div className="flex items-center justify-between gap-4 xl:gap-2.5">
            <div className="flex items-center gap-1">
              <IconWrapper size="sm">
                <UserCircleIcon />
              </IconWrapper>
              <DetailCard.Label>{t`Coach`}</DetailCard.Label>
              <DetailCard.Description>{coachName}</DetailCard.Description>
            </div>

            <div className="flex items-center gap-1">
              <IconWrapper size="sm">
                <ClockIcon />
              </IconWrapper>
              <DetailCard.Description>{duration}</DetailCard.Description>
              <DetailCard.Label>{t`min`}</DetailCard.Label>
            </div>
          </div>
        </div>
      </DetailCard.Root>

      {joinUrl && (
        <Banner.ActionButton asChild>
          <Link to={joinUrl} target="_blank">
            <IconWrapper size="sm">
              <VideoCameraIcon />
            </IconWrapper>
            {t`Join now`}
          </Link>
        </Banner.ActionButton>
      )}
    </Banner.Root>
  );
};
