import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import {
  OnboardingActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { useOnboardingStore } from "@/stores";
import { SubscriptionContent, SubscriptionInfo } from "./sections";

interface SubscriptionPlanSelectionProps {
  onGoBack: () => void;
  onContinue: () => void;
}

export const SubscriptionPlanSelection = ({
  onGoBack,
  onContinue,
}: SubscriptionPlanSelectionProps) => {
  const subscriptionPlan = useOnboardingStore(
    (state) => state.subscriptionPlan,
  );

  return (
    <OnboardingLayout className="gap-6 bg-brown-02">
      <OnboardingHeader title={t`Choose your plan`} />

      <div className="flex w-full grow flex-col gap-8">
        <SubscriptionContent />

        <SubscriptionInfo />

        <div className="mt-auto pb-9 pt-1">
          <OnboardingActionButtons
            onCancel={onGoBack}
            onContinue={onContinue}
            continueDisabled={!subscriptionPlan?.subscriptionPriceId}
          />
        </div>
      </div>
    </OnboardingLayout>
  );
};
