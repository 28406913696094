import { Navigate, Route } from "react-router-dom";

import { BASE_ROUTE, ROUTES } from "@/router";
import { GuestRoutesWrapper } from "@/router/components/GuestRoutesWrapper";
import type { DomainRoute } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import {
  IntakeForm,
  JourneyBegin,
  MainInfo,
  PaymentAndAccount,
} from "./screens";

const getPartialRoute = (route: DomainRoute) => {
  return route.replace(BASE_ROUTE.ONBOARDING, "");
};

export const OnboardingRouter = () => {
  // TODO: Review this, seems weird. Reference: https://app.clickup.com/t/86duvkd6b
  const hasOnboardingUser = useOnboardingStore(
    (state) => !!state.user || !!state.bmi || !!state.birthInfo,
  );

  return (
    <GuestRoutesWrapper>
      <Route
        index
        element={<MainInfo />}
        path={getPartialRoute(ROUTES.ONBOARDING.MAIN_INFO)}
      />

      <Route
        element={<JourneyBegin />}
        path={getPartialRoute(ROUTES.ONBOARDING.JOURNEY_BEGIN)}
      />

      {hasOnboardingUser && (
        <>
          <Route
            element={<PaymentAndAccount />}
            path={getPartialRoute(ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT)}
          />
          <Route
            element={<IntakeForm />}
            path={getPartialRoute(ROUTES.ONBOARDING.INTAKE_FORM)}
          />
        </>
      )}

      <Route
        element={<Navigate replace to={ROUTES.ONBOARDING.MAIN_INFO} />}
        path="*"
      />
    </GuestRoutesWrapper>
  );
};
