import { useNavigate, useSearchParams } from "react-router-dom";

const MAX_PARAM_LENGTH = 100;

const isValidParamValue = (value: string) => {
  return /^[a-zA-Z0-9 _+-]+$/.test(value);
};

const sanitizeString = (value: string) => {
  value = value.trim();
  value = value.replace(/<\/?[^>]+(>|$)/g, "");
  return encodeURIComponent(value);
};

const isPathTraversal = (value: string) => {
  return /(\.\.\/|\.\.\\|%2e%2e%2f|%2e%2e\/)/i.test(value);
};

export const useNavigateWithCurrentQueryParams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sanitizedParams = new URLSearchParams();

  for (const [key, value] of searchParams) {
    const safeKey = sanitizeString(key);
    const safeValue = decodeURIComponent(sanitizeString(value));

    if (
      safeKey &&
      safeValue &&
      isValidParamValue(safeKey) &&
      isValidParamValue(safeValue) &&
      !isPathTraversal(safeKey) &&
      !isPathTraversal(safeValue) &&
      safeKey.length <= MAX_PARAM_LENGTH &&
      safeValue.length <= MAX_PARAM_LENGTH
    ) {
      sanitizedParams.append(safeKey, safeValue);
    }
  }

  return (path: string) => {
    navigate({
      pathname: path,
      search: sanitizedParams.toString(),
    });
  };
};
