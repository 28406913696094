import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import { getEmbeddablesUser } from "./embeddables";

export const onboardingQueriesStore = createQueryKeyStore({
  onboarding: {
    embeddables: {
      queryKey: null,
      contextQueries: {
        user: (code: Parameters<typeof getEmbeddablesUser>[0] | null) => ({
          queryKey: [code ?? undefined],
          queryFn: code ? () => getEmbeddablesUser(code) : (skipToken as never),
        }),
      },
    },
  },
});
