import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { createContext, forwardRef, useContext } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Button } from "@/ui";

const bannerVariants = tv({
  slots: {
    root: "relative z-10 flex flex-col gap-4 rounded-2xl p-4 px-4 py-5 lg:flex-row lg:items-center lg:gap-7 lg:px-8 lg:py-6",
    background:
      "pointer-events-none absolute inset-0 -z-10 flex h-full w-full flex-row justify-end gap-2.5 overflow-hidden rounded-2xl lg:justify-end",
    content: "flex grow flex-col gap-4 pt-2.5 lg:pt-0",
    title: "text-2xl font-bold",
    tagline: "text-sm",
    description: "text-sm",
    actionButton: "font-bold",
  },
  variants: {
    color: {
      nature: {
        root: "bg-nature-03 text-nature-10",
        background: "bg-nature-03 text-nature-05",
        content: "text-nature-10",
        title: "text-nature-09",
      },
      salmon: {
        root: "bg-salmon-03 text-salmon-10",
        background: "bg-salmon-03 text-salmon-05",
        content: "text-salmon-10",
        title: "text-salmon-09",
      },
      brown: {
        root: "bg-brown-03 text-brown-10",
        background: "bg-brown-03 text-brown-05",
        content: "text-brown-10",
        title: "text-brown-09",
      },
      "nature-dark": {
        root: "bg-nature-dark-03 text-nature-dark-10",
        background: "bg-nature-dark-03 text-nature-dark-05",
        content: "text-nature-dark-10",
        title: "text-nature-dark-09",
      },
    },
  },
  defaultVariants: {
    color: "nature",
  },
});

const BannerVariantsContext = createContext<
  VariantProps<typeof bannerVariants>
>({});

const useBannerVariantsContext = () => {
  const context = useContext(BannerVariantsContext);
  return bannerVariants(context);
};

const Root = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & VariantProps<typeof bannerVariants>
>(({ className, color, ...props }, ref) => {
  const { root } = useBannerVariantsContext();

  return (
    <BannerVariantsContext.Provider value={{ color }}>
      <div ref={ref} className={root({ className })} {...props} />
    </BannerVariantsContext.Provider>
  );
});
Root.displayName = "Banner.Root";

const Background = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  const { background } = useBannerVariantsContext();

  return <div ref={ref} className={background({ className })} {...props} />;
});
Background.displayName = "Banner.Background";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { content } = useBannerVariantsContext();

    return <div ref={ref} className={content({ className })} {...props} />;
  },
);
Content.displayName = "Banner.Content";

const Title = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ className, children, ...props }, ref) => {
    const { title } = useBannerVariantsContext();

    return (
      <h3 ref={ref} className={title({ className })} {...props}>
        {children}
      </h3>
    );
  },
);
Title.displayName = "Banner.Title";

const Tagline = forwardRef<ElementRef<"h4">, ComponentPropsWithoutRef<"h4">>(
  ({ className, children, ...props }, ref) => {
    const { tagline } = useBannerVariantsContext();

    return (
      <h4 ref={ref} className={tagline({ className })} {...props}>
        {children}
      </h4>
    );
  },
);
Tagline.displayName = "Banner.Tagline";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    const { description } = useBannerVariantsContext();

    return <p ref={ref} className={description({ className })} {...props} />;
  },
);
Description.displayName = "Banner.Description";

const ActionButton = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => {
  const { actionButton } = useBannerVariantsContext();

  return (
    <Button
      ref={ref}
      size="lg"
      className={actionButton({ className })}
      {...props}
    />
  );
});
ActionButton.displayName = "Banner.ActionButton";

export const Banner = {
  Root,
  Background,
  Content,
  Title,
  Tagline,
  Description,
  ActionButton,
};
