import { useState } from "react";

import { useNavigateWithCurrentQueryParams } from "@/domains/onboarding/hooks";
import { ROUTES } from "@/router";
import {
  HealthiePasswordForm,
  PaymentForm,
  SubscriptionPlanSelection,
} from "./sections";

const STEP = {
  PLAN_SELECTION: "plan-selection",
  PAYMENT_FORM: "payment-form",
  ACCOUNT_FORM: "account-form",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const PaymentAndAccount = () => {
  const [step, setStep] = useState<Step>(STEP.PLAN_SELECTION);
  const navigateWithQuery = useNavigateWithCurrentQueryParams();

  const renderStep = () => {
    switch (step) {
      case STEP.PLAN_SELECTION:
        return (
          <SubscriptionPlanSelection
            onGoBack={() => navigateWithQuery(ROUTES.ONBOARDING.JOURNEY_BEGIN)}
            onContinue={() => setStep(STEP.PAYMENT_FORM)}
          />
        );
      case STEP.PAYMENT_FORM:
        return (
          <PaymentForm
            onGoBack={() => setStep(STEP.PLAN_SELECTION)}
            onSubmit={() => setStep(STEP.ACCOUNT_FORM)}
          />
        );
      case STEP.ACCOUNT_FORM:
        return (
          <HealthiePasswordForm
            onSubmit={() => {
              navigateWithQuery(ROUTES.ONBOARDING.INTAKE_FORM);
            }}
          />
        );
    }
  };

  return renderStep();
};
