export const handleEverflowConvertEvent = (amount: number) => {
  if (!window?.EF) return;
  void window.EF.click({
    offer_id: window.EF.urlParameter("oid"),
    affiliate_id: window.EF.urlParameter("affid"),
    sub1: window.EF.urlParameter("sub1"),
    sub2: window.EF.urlParameter("sub2"),
    sub3: window.EF.urlParameter("sub3"),
    sub4: window.EF.urlParameter("sub4"),
    sub5: window.EF.urlParameter("sub5"),
    uid: window.EF.urlParameter("uid"),
    source_id: window.EF.urlParameter("source_id"),
    transaction_id: window.EF.urlParameter("_ef_transaction_id"),
  });
  void window.EF.conversion({
    aid: 1,
    amount,
  });
};
