import type { PaymentIntent } from "@stripe/stripe-js";

import { analytics } from "@/App";
import type { Plan } from "@/domains/billing/models";
import type { OnboardingUser } from "@/shared.types";

export const CUSTOMER_IO_EVENT_TYPES = {
  EMAIL_SUBMITTED: "emailSubmitted",
  SMS_SUBMITTED: "smsSubmitted",
  PRODUCT_ITEM_CLICKED: "productItemClicked",
  CHECKOUT_PAGE_VIEWED: "checkoutPageViewed",
  PAYMENT_COMPLETED: "paymentCompleted",
} as const;

interface EmailSubmittedEvent {
  user: OnboardingUser;
  formId: string;
  marketingConsent: boolean;
}

interface PhoneSubmittedEvent {
  user: OnboardingUser;
  phoneNumber: string;
  formId: string;
  campaignId?: string;
}

interface SubscriptionItemClickedEvent {
  user: OnboardingUser;
  plan: Plan;
}

interface CheckoutPageViewedEvent {
  user: OnboardingUser;
  plan: Plan;
}

interface PaymentCompletedEvent {
  user: OnboardingUser;
  payment: PaymentIntent;
  discountCode: string | null;
  plan: Plan;
}

export const identifyOnboardingUser = async (user: OnboardingUser) => {
  await analytics.identify(user.code, {
    firstName: user.firstName,
    lastName: user.lastName,
    dateOfBirth: user.dateOfBirth,
    gender: user.gender,
    heightFeet: user.heightFeet,
    heightInches: user.heightInches,
    weight: user.weight,
  });
};

export const handleEmailSubmittedEvent = async ({
  user,
  formId,
  marketingConsent,
}: EmailSubmittedEvent) => {
  await analytics.alias(user.email, user.code);

  await Promise.all([
    analytics.identify(user.email, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      state: user.state,
      language: user.language,
      marketingConsent,
    }),
    analytics.track(CUSTOMER_IO_EVENT_TYPES.EMAIL_SUBMITTED, {
      userId: user.email,
      email: user.email,
      formId,
    }),
  ]);
};

export const handlePhoneNumberSubmittedEvent = async ({
  user,
  phoneNumber,
  formId,
  campaignId,
}: PhoneSubmittedEvent) => {
  await Promise.all([
    analytics.identify(user.email, {
      phone: phoneNumber,
    }),

    analytics.track(CUSTOMER_IO_EVENT_TYPES.SMS_SUBMITTED, {
      phoneNumber,
      campaignId,
      formId,
    }),
  ]);
};

export const handleSubscriptionItemClickedEvent = async ({
  user,
  plan,
}: SubscriptionItemClickedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.PRODUCT_ITEM_CLICKED, {
    userId: user.email,
    productId: plan.subscriptionPriceId,
    productName: plan.name,
    productFrequency: plan.durationInMonths,
    price: plan.price,
  });
};

export const handleCheckoutPageViewed = async ({
  user,
  plan,
}: CheckoutPageViewedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.CHECKOUT_PAGE_VIEWED, {
    userId: user.email,
    products: [
      {
        productId: plan.subscriptionPriceId,
        productName: plan.name,
        productFrequency: plan.durationInMonths,
        price: plan.price,
      },
    ],
    totalPrice: plan.price,
  });
};

export const handlePaymentCompletedEvent = async ({
  user,
  payment,
  discountCode,
  plan,
}: PaymentCompletedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.PAYMENT_COMPLETED, {
    userId: user.email,
    paymentId: payment.id,
    amountPaidInCents: payment.amount,
    discountCode,
    products: [
      {
        productId: plan.subscriptionPriceId,
        productName: plan.name,
        productFrequency: plan.durationInMonths,
        price: plan.price,
      },
    ],
  });
};
