import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { OnboardingUser } from "@/shared.types";

export const getEmbeddablesUser = async (code: OnboardingUser["code"]) => {
  const { data } = await publicAPI.get<ServiceResponse<OnboardingUser>>(
    `/embeddables/users/${code}`,
  );

  return data.data;
};
