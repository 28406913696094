import { useState } from "react";
import { Navigate } from "react-router-dom";

import { LoadingScreen } from "@/components";
import { useUserFromEmbeddables } from "@/domains/onboarding/hooks";
import { ROUTES } from "@/router";
import {
  Delivery,
  JourneyBeginCover,
  OnboardingScheduleVisit,
} from "./sections";

const STEP = {
  COVER: "cover",
  APPOINTMENT: "appointment",
  DELIVERY: "delivery",
  ERROR: "error",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const JourneyBegin = () => {
  const [step, setStep] = useState<Step>(STEP.COVER);

  const { isError, isLoading } = useUserFromEmbeddables();

  if (isError) {
    return <Navigate replace to={ROUTES.ONBOARDING.MAIN_INFO} />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {step === STEP.COVER && <JourneyBeginCover onContinue={setStep} />}

      {step === STEP.APPOINTMENT && (
        <OnboardingScheduleVisit onGoBack={() => setStep(STEP.COVER)} />
      )}

      {step === STEP.DELIVERY && (
        <Delivery
          onGoBack={() => setStep(STEP.COVER)}
          onDisqualified={() => setStep(STEP.APPOINTMENT)}
        />
      )}
    </>
  );
};
